@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply text-gray-900 antialiased;
  }
}

@layer components {
  .btn-primary {
    @apply bg-teal-500 text-white px-6 py-3 rounded-lg font-semibold 
           hover:bg-teal-600 transition duration-300;
  }

  .btn-secondary {
    @apply bg-white text-teal-500 px-6 py-3 rounded-lg font-semibold 
           hover:bg-gray-50 transition duration-300 border border-teal-500;
  }
}
